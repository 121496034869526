import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After creating your deployment inside Atomic, it's time to configure a GitHub action that deploys your changes when an update has occurred. Here's how to do it:`}</p>
    <p>{`(`}<em parentName="p"><strong parentName="em">{`Note:`}</strong>{` These steps are simplified to get you started at Pagely. If you're integrating an existing production site with Pagely or would like more complete options, see our `}<a parentName="em" {...{
          "href": "https://support.pagely.com/hc/en-us/articles/360047749731-Automatically-Deploying-Your-WordPress-Site-with-GitHub-Actions"
        }}>{`deploying WordPress sites from GitHub Actions`}</a>{` on our main documentation site.)`}</em></p>
    <h2>{`Configuring GitHub Repository Secrets`}</h2>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/github/github-repo-code-click-settings.png" alt="Screenshot: GitHub repo settings tab" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Start by accessing your GitHub repository and accessing the repository's `}<strong parentName="p">{`Settings`}</strong>{` tab.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/github/repo-settings/repo-settings-click-secrets.png" alt="Screenshot: click on secrets" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, click on `}<strong parentName="p">{`Secrets`}</strong>{` to access your repository's secrets.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/github/repo-settings/secrets/secrets-click-new-secret.png" alt="Screenshot: click on new secret" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Let's start adding a new secret by clicking on the `}<strong parentName="p">{`New Secret`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/github/repo-settings/secrets/new-secret-name-field-integration-secret.png" alt="Screenshot: secret name field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Inside the `}<strong parentName="p">{`Name`}</strong>{` field of the secret, we'll set a variable name of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`PAGELY_INTEGRATION_SECRET`}</code>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/github/repo-settings/secrets/new-secret-value-field-integration-secret.png" alt="Screenshot: integration secret value field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Now our secret needs a value. Inside the `}<strong parentName="p">{`Value`}</strong>{` field, enter the `}<strong parentName="p">{`Integration Secret`}</strong>{` that was generated for you when creating the integration in Atomic.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/github/repo-settings/secrets/new-secret-add-secret-button.png" alt="Screenshot: add secret button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Now that the secret's name and value are filled, click on the `}<strong parentName="p">{`Add Secret`}</strong>{` button to save it.`}</p>
      </li>
    </ol>
    <h2>{`Creating a GitHub Actions Deployment Step`}</h2>
    <p>{`It's time to create a pipeline that will use Pagely's deployment action to handle all of the heavy lifting.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Start by creating a file within your repository at `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.github/workflows/pagely-deploy.yml`}</code>{` with the following contents:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "yaml"
        }}><pre parentName="div" {...{
            "className": "language-yaml"
          }}><code parentName="pre" {...{
              "className": "language-yaml"
            }}><span parentName="code" {...{
                "className": "token key atrule"
              }}>{`name`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"pagely-deploy"`}</span>{`
`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`on`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` push
`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`jobs`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
  `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`deploy`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`name`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` Deploy to Pagely
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`runs-on`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` ubuntu`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`latest
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`steps`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
      `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{` `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`name`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` Checkout repo
        `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`uses`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` actions/checkout@v2
      `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{` `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`name`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` Run deploy
        `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`uses`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` godaddy`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`wordpress/pagely`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`deploy`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`action@v1
        `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`with`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
          `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_DEPLOY_DEST`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"/httpdocs"`}</span>{`
          `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_INTEGRATION_SECRET`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` $`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`secrets.PAGELY_INTEGRATION_SECRET`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
          `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_INTEGRATION_ID`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"HhYn7RelGEiYFJz7nzvKl9"`}</span>{`
          `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_APP_ID`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"12345"`}</span>{`
          `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_WORKING_DIR`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` $`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`github.workspace`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`}`}</span></code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next, we'll need to change a few variables to reflect your site. These variables are as follows:`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`PAGELY_DEPLOY_DEST`}</strong>{`: This sets the destination directory that you will be depoying to, with `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/httpdocs`}</code>{` representing your site's root directory.
If you're deploying your whole site from the repository, you'll leave this as it is in the example. If you're deploying a single plugin or theme, you'll want to change this to something like `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/httpdocs/wp-content/plugins/example-plugin-dir`}</code>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_INTEGRATION_SECRET`}</strong>{`: This is a variable containing the deployment secret that we set up earlier. You'll only need to change this if you used a different name for your secret.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_INTEGRATION_ID`}</strong>{`: This contains the integration ID. You'll need to change this to reflect the integration ID that you were provided in Atomic.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_APP_ID`}</strong>{`: This is the ID of the app that you'll be deploying too. You can find this inside your app in Atomic.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_WORKING_DIR`}</strong>{`: This sets the directory that will be deployed. In the example above, we're using the repository's root directory. You'll only need to change this if you have a different directory that you'll be deploying from.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Finally, triple-check over your settings to make sure you haven't made any typos, then commit your changes. From now on, any new pushes to the repository will be deployed to your WordPress site. This`}</p>
      </li>
    </ol>
    <p>{`At this point, you've finished creating an automatic deployment workflow using GitHub Actions. For more information not covered in this quickstart guide, take a look our support documentation for `}<a parentName="p" {...{
        "href": "https://support.pagely.com/hc/en-us/articles/360047749731-Automatically-Deploying-Your-WordPress-Site-with-GitHub-Actions"
      }}>{`deploying WordPress sites with GitHub Actions`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      